<template>
  <div class="iss-main-grid">
    <div class="top-header-nav">
      <a-menu v-model:selectedKeys="current" mode="horizontal"  :style="{ borderBottom: 'none' }">
        <a-menu-item v-for="item in headerNavList" :key="item.key">
          {{ item.label }}
        </a-menu-item>
      </a-menu>
      <div>
       <goldMall v-if="current[0]=='goldMall'"></goldMall>
       <cloudSpace v-else-if="current[0]=='cloudSpace'"></cloudSpace>
       <giftCards v-else-if="current[0]=='giftCards'"></giftCards>
       <spend v-else-if="current[0]=='spend'"></spend>
      </div>
    </div> 
  </div>
</template>

<script>
import { reactive,  toRefs } from 'vue';
import {  Menu } from 'ant-design-vue';

import cloudSpace from './components/cloudSpace.vue';
import goldMall from './components/goldMall.vue';
import giftCards from './components/giftCards.vue';
import spend from './components/spend.vue';

export default {
  components: {
    AMenu: Menu,
    AMenuItem: Menu.Item,
    cloudSpace,
    goldMall,
    spend,
    giftCards

  },
  setup() {
    const states = reactive({
      current: ['goldMall'],
      headerNavList: [
        {
          label: '金币商城',
          key: 'goldMall',
        },
        {
          label: '云空间',
          key: 'cloudSpace',
        },
        {
          label: '京东购物卡',
          key: 'giftCards',
        },
        {
          label: '话费',
          key: 'spend',
        },
      ],
    });
    return {
      ...toRefs(states),
    };
  },
};
</script>

<style scoped lang="less">

/deep/ .ant-menu{
  background-color: transparent;
}

</style>
